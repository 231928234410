import React from 'react';

export default {
  info: {
    path: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.877 88.375c11.67 0 22.86-4.637 31.113-12.887a44.011 44.011 0 0012.887-31.113c0-11.67-4.637-22.86-12.887-31.113A44.01 44.01 0 0044.877.375a44.008 44.008 0 00-31.113 12.887A44.01 44.01 0 00.877 44.375c0 11.67 4.637 22.86 12.887 31.113a44.01 44.01 0 0031.113 12.887zm0-68.75a5.5 5.5 0 11-.001 11.002 5.5 5.5 0 010-11.002zm-5.5 19.25h11v30.25h-11v-30.25z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 89 89',
  },
  warning: {
    path: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44 0a44.008 44.008 0 00-31.113 12.887A44.01 44.01 0 000 44c0 11.67 4.637 22.86 12.887 31.113A44.01 44.01 0 0044 88c11.67 0 22.86-4.637 31.113-12.887A44.01 44.01 0 0088 44c0-11.67-4.637-22.86-12.887-31.113A44.01 44.01 0 0044 0zm0 68.75a5.5 5.5 0 110-11.002 5.5 5.5 0 010 11.002zm5.5-19.25h-11V19.25h11V49.5z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 88 88',
  },
  chevronUp: {
    path: (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44 0l44 44-11 11-32.5-32.5L44 0z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44 0l.5 22.5L11 55 0 44 44 0z"
          fill="currentColor"
        />
      </>
    ),
    viewBox: '0 0 88 55',
  },
  chevronDown: {
    path: (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44 55l44-44L77 0 44.5 32.5 44 55z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44 55l.5-22.5L11 0 0 11l44 44z"
          fill="currentColor"
        />
      </>
    ),
    viewBox: '0 0 88 55',
  },
  openInNew: {
    path: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.405 10.152V.374h34.221v34.222H78.85V17.045L30.792 65.102 23.9 58.208l48.057-48.056h-17.55zM10.404 78.596h68.442V44.374h9.778v34.222c0 5.377-4.4 9.777-9.777 9.777H10.404c-5.427 0-9.778-4.4-9.778-9.777V10.153c0-5.378 4.351-9.778 9.778-9.778h34.221v9.778H10.404v68.443z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 89 89',
  },
  arrowLeft: {
    path: (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88 37.854H11.8v-9.83H88v9.83z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.936 65.88l6.886-6.886-26.06-26.054 26.06-26.054L32.936 0 0 32.94l32.936 32.94z"
          fill="currentColor"
        />
      </>
    ),
    viewBox: '0 0 88 66',
  },
  arrowRight: {
    path: (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M.126 38.21h76.201v-9.83H.126v9.83z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.19 66.24l-6.885-6.887 26.06-26.055-26.06-26.054L55.19.358l32.936 32.94L55.19 66.24z"
          fill="currentColor"
        />
      </>
    ),
    viewBox: '0 0 89 67',
  },
  help: {
    path: (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 44C0 19.712 19.712 0 44 0s44 19.712 44 44-19.712 44-44 44S0 68.288 0 44zm48.4 26.4h-8.8v-8.8h8.8v8.8zM44 79.2C24.596 79.2 8.8 63.404 8.8 44 8.8 24.596 24.596 8.8 44 8.8c19.404 0 35.2 15.796 35.2 35.2 0 19.404-15.796 35.2-35.2 35.2zm-17.6-44c0-9.724 7.876-17.6 17.6-17.6s17.6 7.876 17.6 17.6c0 5.645-3.476 8.683-6.86 11.64-3.212 2.806-6.34 5.54-6.34 10.36h-8.8c0-8.014 4.145-11.191 7.79-13.985 2.86-2.191 5.41-4.147 5.41-8.015 0-4.84-3.96-8.8-8.8-8.8-4.84 0-8.8 3.96-8.8 8.8h-8.8z"
          fill="currentColor"
        />
      </>
    ),
    viewBox: '0 0 88 88',
  },
  gavel: {
    path: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.261 20.753L51.884 31.13 31.132 10.377 41.508 0 62.26 20.753zm-36.337-5.206L15.547 25.924 67.394 77.77 77.77 67.394 25.924 15.547zM44 73.334H0v7.333h44v-7.333zM31.13 51.846L10.377 31.093 0 41.47l20.753 20.753L31.13 51.846z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 78 81',
  },
  account: {
    path: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.406.756H78.85c5.378 0 9.778 4.4 9.778 9.778v68.444c0 5.378-4.4 9.778-9.778 9.778H10.406c-5.427 0-9.778-4.4-9.778-9.778V10.534c0-5.378 4.351-9.778 9.778-9.778zM78.85 78.979V10.534H10.405V78.98H78.85zM44.626 44.756c-8.066 0-14.666-6.6-14.666-14.667 0-8.066 6.6-14.667 14.666-14.667 8.067 0 14.667 6.6 14.667 14.667s-6.6 14.667-14.667 14.667zm-4.888-14.667c0-2.689 2.2-4.889 4.89-4.889 2.688 0 4.888 2.2 4.888 4.89 0 2.688-2.2 4.888-4.889 4.888a4.903 4.903 0 01-4.889-4.889zm-24.444 44v-7.48c0-12.221 19.409-17.501 29.333-17.501S73.96 54.388 73.96 66.61v7.48H15.294zm29.333-15.253c6.404 0 14.667 2.737 18.04 5.475h-36.08c3.324-2.738 11.635-5.476 18.04-5.476z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 89 89',
  },
  document: {
    path: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.627.756h-35.2c-4.84 0-8.8 3.96-8.8 8.8v70.4c0 4.84 3.916 8.8 8.756 8.8h52.844c4.84 0 8.8-3.96 8.8-8.8v-52.8l-26.4-26.4zm8.8 44h-35.2v8.8h35.2v-8.8zm0 17.6h-35.2v8.8h35.2v-8.8zm-44 17.6h52.8v-48.4h-22v-22h-30.8v70.4z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 72 89',
  },
  loader: {
    path: (
      <>
        <circle
          opacity={0.1}
          cx={67}
          cy={67}
          r={64}
          fill="none"
          stroke="#002664"
          strokeWidth={6}
        />
        <path
          d="M131 67c0 17.544-7.059 33.439-18.492 45M3 67c0-17.544 7.06-33.439 18.492-45"
          fill="none"
          stroke="#002664"
          strokeWidth={6}
        />
      </>
    ),
    viewBox: '0 0 134 134',
  },
  loaderDisabled: {
    path: (
      <circle
        opacity={0.1}
        cx={67}
        cy={67}
        r={64}
        fill="none"
        stroke="#002664"
        strokeWidth={6}
      />
    ),
    viewBox: '0 0 134 134',
  },
  error: {
    path: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.667 0l58.666 101.333H0L58.667 0zm-5.334 64H64V42.665H53.333v21.333zm0 21.333H64V74.666H53.333v10.667z"
        fill="#DADADA"
      />
    ),
    viewBox: '0 0 118 102',
  },
};
