import { theme, ITheme } from '@chakra-ui/core';

import icons from './icons';

interface CustomColors {
  brand: {
    blue: {
      primary: string;
      secondary: string;
      tertiary: string;
      faded: string;
    };
    orange: {
      primary: string;
      faded: string;
    };
    red: {
      primary: string;
    };
    text: string;
  };
}

interface CustomSizes {
  contentMax: string;
}

interface CustomShadows {
  card: string;
  focus: string;
}

export interface CustomTheme extends ITheme {
  colors: ITheme['colors'] & CustomColors;
  shadows: ITheme['shadows'] & CustomShadows;
  sizes: ITheme['sizes'] & CustomSizes;
}

export const rem = (px: string) => {
  return `${parseInt(px, 10) / 16}rem`;
};

const spacing = {
  '0': '0',
  '1': rem('8px'),
  '2': rem('12px'),
  '3': rem('16px'),
  '4': rem('24px'),
  '5': rem('32px'),
  '6': rem('48px'),
  '8': rem('56px'),
  '10': rem('64px'),
  '12': rem('80px'),
  '13': rem('96px'),
  '14': rem('120px'),
  '15': rem('164px'),
};

const customTheme: CustomTheme = {
  borders: theme.borders,
  breakpoints: [rem('1024px')],
  colors: {
    ...theme.colors,
    brand: {
      blue: {
        primary: '#002664',
        secondary: '#2E5299',
        tertiary: '#009ED5',
        faded: '#EBEEF5',
      },
      orange: {
        primary: '#DC5800',
        faded: '#FCEFE6',
      },
      red: {
        primary: '#9D1C46',
      },
      text: '#333',
    },
  },
  fonts: {
    body: 'Montserrat, sans-serif',
    heading: 'Montserrat, sans-serif',
    mono: 'monospace',
  },
  fontSizes: {
    xs: rem('16px'),
    sm: rem('18px'),
    md: rem('20px'),
    lg: rem('24px'),
    xl: rem('26px'),
    '2xl': rem('32px'),
    '3xl': rem('56px'),
    '4xl': rem('64px'),
    '5xl': rem('72px'),
    '6xl': rem('80px'),
  },
  fontWeights: theme.fontWeights,
  icons: icons,
  letterSpacings: theme.letterSpacings,
  lineHeights: theme.lineHeights,
  opacity: theme.opacity,
  radii: {
    ...theme.radii,
    sm: rem('4px'),
  },
  shadows: {
    ...theme.shadows,
    card: '0 4px 12px rgba(0, 0, 0, 0.15)',
    focus: '0 0 5px #009ED5, inset 0 0 5px #009ED5',
  },
  sizes: {
    ...theme.sizes,
    ...spacing,
    contentMax: rem('1250px'),
  },
  space: {
    ...theme.space,
    ...spacing,
  },
  zIndices: theme.zIndices,
};

export default customTheme;
